import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, Button, Container, IconButton, Link, Menu, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Link as RLink, useNavigate } from "react-router-dom";

import Logo from '../../assets/echo.svg';

export function Header() {
  const navigate = useNavigate();

  return (
    <AppBar position="sticky" sx={{
      background: '#00000022',
      backdropFilter: 'blur(3px)',
      boxShadow: '0px 1px 10px 0px #00000044',
    }}>
      <Toolbar sx={{
        // backgroundColor: '#12121200'
      }}>
        {/* <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <MenuIcon />
      </IconButton> */}
        <Box>
          <RLink to="/">
            <img
              src={Logo}
              style={{ height: '100%', width: 'auto' }}
              alt="Echo Logo"
            />
          </RLink>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="inherit"
          onClick={() => {
            navigate('/')
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }}>
          Features
        </Button>
        <Button
          color="inherit"
          onClick={() => {
            navigate('/app')
            // window.scrollTo({
            //   top: 10000,
            //   behavior: "smooth",
            // })
          }}>
          APP
        </Button>
        <Button
          color="inherit"
          onClick={() => {
            navigate('/app/topup')
          }}>
          Top UP
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
