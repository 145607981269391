import React, { useEffect, useState } from 'react'
import ReactSlider from 'react-slider'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { Box, CardActionArea } from '@mui/material';
import './BusinessOffer.css'
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'
import {
  appearanceState,
  topUpAmountState,
} from '../state'

const tokenPrice = 0.01

export default function BusinessOffer(props) {

  return (
    <Stack
      direction="row"
      spacing={{ xs: 0, sm: 1 }}
      sx={{
        mt: 1,
        mb: 2,
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: 'stretch',
        flexWrap: 'wrap',
      }}
    >
      <Plan {...props} />
    </Stack>
  )
}

export function Plan(props) {
  const appearance = useRecoilValue(appearanceState)
  const setTopUpAmount = useSetRecoilState(topUpAmountState)

  const [seats, setSeats] = useState(1)
  const [locations, setLocations] = useState(10)

  const locationsPrice = locations * 1000 * tokenPrice
  let monthlyPrice = locationsPrice

  useEffect(() => {
    setTopUpAmount(monthlyPrice)
  }, [seats, locations, setTopUpAmount])

  return (
    <Card
      sx={{
        mt: { xs: 1, sm: 0 },
        backgroundFilter: 'blur(2px)',
        backgroundColor: '#00000011',
        flex: { xs: '1 1 80%', sm: '1 1 30%', },
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.2)',
        border: '1px solid #ffffff11',
        borderColor: '#ffffff11'
      }}
    >
      <CardActionArea sx={{
        height: '100%',
        width: { xs: '90vw', sm: '80vw', md: '60vw', lg: '40vw' },
        // minWidth: { xs: '90vw', md: '1vw', lg: '1vw' },
      }}>
        <CardContent sx={{ flex: '0 0 auto' }}>
          <Box sx={{
            display: 'flex',
            width: 1,
            mb: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Stack direction={{ xs: 'column', sm: 'row' }}>
              <Typography variant="h5">
                Echo AI Tokens
              </Typography>
            </Stack>

            <Chip
              label={`Save 15%`}
              variant={'outlined'}
              color={'default'}
              sx={{
                backgroundColor: 'default',
                borderColor: '#ffffff55',
                color: '#ffffff'
              }}
            />
          </Box>

          <Typography
            variant="h6"
            color="text.primary"
            sx={{
              mb: 1,
              fontWeight: 500
            }}
          >
            Tokens amount {formatNumber(locations * 10000)}
          </Typography>

          <ReactSlider
            marks
            className={`horizontal-slider CustomPlan-${appearance} BusinessLocations-${appearance}`}
            thumbClassName='locations-thumb'
            trackClassName='locations-track'
            markClassName='locations-mark'
            min={0}
            max={999}
            value={locations - 1}
            defaultValue={1}
            onBeforeChange={(value, index) => {

              console.log(
                `onBeforeChange: ${JSON.stringify({
                  value,
                  index
                })}`
              )
            }}
            onChange={(value, index) => {
              // console.log(`ReactSlider onChange: value ${value} index ${index}`)
              // console.log(`ReactSlider setLocations ${value + 1}`)
              setLocations(value + 1)
            }}
            onAfterChange={(value, index) =>
              console.log(
                `onAfterChange: ${JSON.stringify({ value, index })}`
              )
            }
            renderTrack={(props, state) => <div {...props}  key={props.key} />}
            renderThumb={(props, state) => (
              <div{...props}  key={props.key} >{formatNumber((state.valueNow + 1) * 10000)}</div>
            )}
            renderMark={props => <div {...props}  key={props.key} />}
          />


          <Typography
            variant="h6"
            color="text.primary"
            sx={{
              mt: 2,
              mb: 2,
              fontWeight: 500
            }}
          >
            Estimated english words: {formatNumber(locations * 200000 * 0.75)}
          </Typography>

        </CardContent>
      </CardActionArea>
    </Card>
  )
}

function formatNumber(num, significantDigits = 3) {
  // Включаем массив суффиксов внутрь функции для полной инкапсуляции
  const suffixes = {
    1000: 'K',
    1000000: 'M',
    1000000000: 'B',
    1000000000000: 'T'
  };

  const absNum = Math.abs(num);

  if (absNum >= 1000) {
    let scale = 1;
    let suffix = '';
    let scaledNum = num;

    // Определяем нужный делитель и суффикс
    while (scaledNum >= 1000 && scale <= 1e12) {
      scale *= 1000;
      scaledNum = num / scale;
      suffix = suffixes[scale] || '';
    }

    // Округляем число в зависимости от величины
    if (scaledNum >= 100) {
      return (Math.round(scaledNum) + suffix);
    } else if (scaledNum >= 10) {
      return (parseFloat(scaledNum.toFixed(1)) + suffix);
    } else {
      return (parseFloat(scaledNum.toFixed(2)) + suffix);
    }
  } else if (absNum < 1) {
    // Используем toPrecision для чисел меньше 1
    return num.toPrecision(significantDigits);
  } else {
    // Числа от 1 до 999, применяем логику похожую на большие числа, но без суффикса
    if (num >= 100) {
      return Math.round(num).toString();
    } else if (num >= 10) {
      return num.toFixed(1);
    } else {
      return num.toFixed(2);
    }
  }
}