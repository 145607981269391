export default {
  "languages": {
      "en": "英語",
      "ru": "ロシア語",
      "de": "ドイツ語",
      "es": "スペイン語",
      "fr": "フランス語",
      "ja": "日本語"
  },
  "hello": {
      "title": "クリックしてプライバシーを保護します",
      "login": "ログイン",
      "register": "Echoアカウントを作成します"
  },
  "servers": {
      "search": "検索"
  },
  "drawer": {
      "vpn": "VPN",
      "settings": "設定",
      "support": "サポート"
  },
  "theme": {
      "current": "現在のテーマ",
      "system": "システム",
      "dark": "暗い",
      "light": "ライト"
  },
  "paywall": {
      "title": "EchoAI プレミアム",
      "upgradeButton": "プレミアムにアップグレード",
      "titleModal": "今すぐプレミアムを購読する",
      "subtitleModal": "すべてのプレミアム機能にアクセスできます",
      "subtitles": [
          "広告は絶対にありません",
          "驚異的な高速接続速度",
          "ミリタリーグレードの暗号化",
          "さまざまな国へのアクセス",
          "技術サポート",
          "今後のすべての機能"
      ],
      "awaiterText": "購入サーバーの応答を待っています",
      "subscribeButton": "無料トライアルを開始",
      "subscribeButtonNoTrial": "今すぐ購読する",
      "trial": "その後3日間の無料トライアル",
      "noTrial": "自動更新可能なサブスクリプション",
      "terms": "利用規約",
      "privacy": "プライバシーポリシー",
      "restore": "購入の復元"
  },
  "dialogs": {
      "wireguardInstall": {
          "title": "ワイヤーガードをインストールします",
          "text": "EchoAIは、公式のWireGuard®クライアントトンネルを使用して接続を保護します。下のボタンを押して、インストールを続行します。",
          "button": "インストール"
      },
      "serviceInstall": {
          "title": "EchoAIサービスのセットアップ",
          "text": "EchoAIはバックグラウンドサービスを使用して接続状態を制御します。下のボタンを押して、インストールを続行します。",
          "button": "設定"
      },
      "serviceRun": {
          "title": "EchoAIサービスを実行します",
          "text": "EchoAIバックグラウンドサービスは実行されていません。下のボタンを押して続行します。",
          "button": "始める"
      },
      "serviceRemove": {
          "title": "EchoAIサービスを削除します",
          "text": "EchoAIバックグラウンドサービスをインストールし、接続状態を制御するために実行する必要があります。とにかく下のボタンを押して削除します。",
          "button": "削除"
      },
      "tunnelRemove": {
          "title": "EchoAI WireGuardトンネルを取り外します",
          "text": "EchoAI WireGuardトンネルが接続を制御します。下のボタンを押して停止して削除します。",
          "button": "トンネルを取り外します"
      },
      "accountExitDialog": {
          "title": "exitアカウント",
          "text": "Echoアカウントを終了したいですか？",
          "mainButton": "出口",
          "cancelButton": "キャンセル"
      },
      "updateDialog": {
          "title": "利用可能な更新",
          "text": "EchoAIアップデートはインストールできます。続行するには、アプリを再起動してください。",
          "mainButton": "再起動",
          "cancelButton": "後で"
      }
  },
  "vpn": {
      "title": "優先ロケーション",
      "buttonConnect": "コネクト",
      "buttonDisconnect": "切断",
      "freeBadge": "無料",
      "premiumBannerLine1": "イエティVPNプレミアムの3日間無料",
      "premiumBannerLine2": "広告なし＆最速のプライベートサーバー",
      "securedConnection": "高速安全な接続",
      "insecureConnection": "不安定な接続",
      "VPNStates": [
          "切断",
          "接続",
          "接続済み",
          "切断",
          "エラー"
      ]
  },
  "support": {
      "title": "コントロールとサポート",
      "wgReinstall": "ワイヤーガードをインストールします",
      "wgReinstallDesc": "[ワイヤーガードのセットアップ]ダイアログを開きます",
      "wgReinstallButton": "開ける",
      "wgReinstallButtonInstalled": "インストール",
      "wgReinstallButtonNotInstalled": "インストールされていない",
      "svcReinstall": "EchoAIサービスをインストールします",
      "svcReinstallDesc": "EchoAIサービスのセットアップダイアログを開きます",
      "svcReinstallButton": "開ける",
      "svcReinstallButtonInstalled": "インストール",
      "svcReinstallButtonNotInstalled": "インストールされていない",
      "svcRun": "EchoAIサービスを実行します",
      "svcRunDesc": "EchoAIサービスを再起動するダイアログを開きます",
      "svcRunButton": "開ける",
      "svcRunButtonRunning": "ランニング",
      "svcRunButtonStopped": "停止",
      "svcRemove": "EchoAIサービスを削除します",
      "svcRemoveDesc": "EchoAIサービスを開くダイアログを削除します",
      "svcRemoveButton": "削除",
      "wgTunnelRemove": "ワイヤーガードトンネルを停止します",
      "wgTunnelRemoveDesc": "接続の問題が発生した場合に行います",
      "wgTunnelRemoveButton": "削除",
      "wgTunnelRemoveButtonRunning": "ランニング",
      "wgTunnelRemoveButtonStopped": "停止"
  },
  "settings": {
      "title": "設定",
      "language": "言語",
      "theme": "テーマ",
      "themeDesc": "アプリの外観を変更します",
      "account": "Echoアカウント",
      "accountControl": "アカウント制御",
      "accountControlDesc": "アカウント制御ページを開くページ",
      "accountControlButton": "開ける",
      "accountLogin": "ログイン",
      "accountLogout": "ログアウト",
      "accountRemove": "アカウントを削除する",
      "subsControl": "サブスクリプションコントロール",
      "startWithSystem": "システムから始めます",
      "startWithSystemDesc": "システムスタートアップでEchoAIを開始します",
      "startHidden": "隠し始めます",
      "startHiddenDesc": "EchoAIを最小限に抑えます",
      "tracking": "匿名の使用データを送信します",
      "trackingDesc": "アプリを改善できるようにします"
  },
  "account": {
      "title": "Echoアカウント",
      "errors": {
          "invalid_password": "メールアドレスまたはパスワードが正しくありません。 再試行",
          "invalid_code": "コードが正しくありません。 再試行",
          "get_profile_error": "メールアドレスまたはパスワードが正しくありません。 再試行",
          "emptyEmail": "電子メールを空にすることはできません!",
          "incorrectEmail": "正しくないメールアドレス！",
          "emptyPass": "パスワードを空にすることはできません!",
          "emptyCode": "コードを空にすることはできません!"
      },
      "login": {
          "title": "ログインする",
          "createPre": "新しいユーザー？",
          "createLink": "登録",
          "emailLabel": "電子メールアドレス",
          "emailPlaceholder": "john.doe@gmail.com",
          "passLabel": "パスワード",
          "passPlaceholder": "***************",
          "mainBtn": "継続する",
          "resetLink": "パスワードをお忘れですか？",
          "otcBtn": "ログインコードを取得する"
      },
      "register": {
          "title": "登録",
          "loginPre": "すでにアカウントをお持ちですか？",
          "loginLink": "ログインする",
          "emailLabel": "電子メールアドレス",
          "emailPlaceholder": "john.doe@gmail.com",
          "passLabel": "パスワード",
          "passPlaceholder": "***************",
          "mainBtn": "継続する"
      },
      "resetPass": {
          "title": "パスワードを再設定する",
          "createPre": "新しいユーザー？",
          "createLink": "登録",
          "emailLabel": "電子メールアドレス",
          "emailPlaceholder": "john.doe@gmail.com",
          "mainBtn": "コードを送信",
          "resetLink": "すでにリセットコードをお持ちですか？",
          "otcBtn": "ログインコードを取得する"
      },
      "updatePass": {
          "title": "パスワードの更新",
          "createPre": "新しいユーザー？",
          "createLink": "登録",
          "codeLabel": "Reset Code",
          "codePlaceholder": "コードをリセット",
          "passLabel": "新しいパスワード",
          "passPlaceholder": "***************",
          "mainBtn": "継続する",
          "resetLink": "リセットコードを取得しませんか？",
          "otcBtn": "ログインコードを取得する"
      },
      "loginSendCode": {
          "title": "コードでログインする",
          "createPre": "新しいユーザー？",
          "createLink": "登録",
          "emailLabel": "電子メールアドレス",
          "emailPlaceholder": "john.doe@gmail.com",
          "mainBtn": "継続する",
          "otcLink": "すでにログインコードをお持ちですか？",
          "loginBtn": "パスワードでログインする"
      },
      "loginCheckCode": {
          "title": "コードでログインする",
          "createPre": "新しいユーザー？",
          "createLink": "登録",
          "emailLabel": "電子メールアドレス",
          "emailPlaceholder": "john.doe@gmail.com",
          "codeLabel": "ログインコード",
          "codePlaceholder": "***************",
          "mainBtn": "継続する",
          "otcLink": "ログインコードを取得しませんか？",
          "loginBtn": "パスワードでログインする"
      },
      "remove": {
        "title": "アカウントを削除",
        "message": "Echo アカウントを完全に削除してもよろしいですか?\nこのアクションは元に戻せません。\n削除後、サブスクリプションを手動でキャンセルする必要がある場合があります。",
        "cancelBtn": "キャンセル",
        "removeBtn": "メールでリンクを削除"
    }
  },
  "location": {
      "region": {
          "Dev": "Dev",
          "Auto": "自動",
          "Favorites": "お気に入り",
          "Latest": "最新",
          "Free": "無料",
          "World": "世界",
          "Americas": "アメリカ大陸",
          "Europe": "ヨーロッパ",
          "Asia": "アジア",
          "AsiaPacific": "アジア太平洋地域",
          "Australia": "オーストラリア"
      },
      "country": {
          "CA": "カナダ",
          "MX": "メキシコ",
          "SE": "スウェーデン",
          "US": "米国",
          "NL": "オランダ",
          "DE": "ドイツ",
          "GB": "イギリス",
          "FR": "フランス",
          "IN": "ドで",
          "KR": "韓国",
          "SG": "シンガポール共和国",
          "JP": "日本",
          "AU": "オーストラリア",
          "RU": "ロシア",
          "FI": "フィンランド",
          "HK": "香港特別行政区",
          "CH": "スイス",
          "ES": "スペイン",
          "PT": "ポルトガル",
          "MD": "モルドバ共和国",
          "IE": "アイルランド",
          "RO": "ルーマニア",
          "HU": "ハンガリー",
          "CZ": "チェコ共和国",
          "LV": "ラトビア",
          "UA": "ウクライナ",
          "IL": "イスラエル",
          "TR": "トルコ",
          "PL": "ポーランド",
          "BG": "ブルガリア",
          "KZ": "カザフスタン",
          "RS": "セルビア",
          "SK": "スロバキア",
          "IT": "イタリア",
          "GR": "ギリシャ"
      },
      "city": {
          "dev": "Dev MSK",
          "auto_fastest": "最速の場所",
          "auto_nearest": "最も近い国",
          "fr_paris_free": "パリ",
          "us_dallas_free": "ダラス",
          "de_frankfurt_free": "フランクフルト",
          "us_ny_free": "ニューヨーク",
          "ru_moscow_free": "モスクワ",
          "auto": "自動",
          "ca_toronto": "トロント",
          "se_stockholm": "ストックホルム",
          "mx_mexico": "メキシコシティ",
          "us_atlanta": "アトランタ",
          "us_chicago": "シカゴ",
          "us_dallas": "ダラス",
          "us_la": "ロサンゼルス",
          "us_miami": "マイアミ",
          "us_ny": "ニューヨーク",
          "us_sf": "サンフランシスコ",
          "us_seattle": "シアトル",
          "us_sv": "シリコンバレー",
          "nl_amsterdam": "アムステルダム",
          "de_frankfurt": "フランクフルト",
          "gb_london": "ロンドン",
          "fr_paris": "パリ",
          "in_bengaluru": "バンガロール",
          "kr_seoul": "ソウル",
          "sg_singapore": "シンガポール",
          "jp_tokio": "東京",
          "au_sydney": "シドニー",
          "ru_moscow": "モスクワ",
          "gb_coventry": "コベントリー",
          "fi_helsinki": "ヘルシンキ",
          "us_secaucus": "セコーカス",
          "hk_hongkong": "香港",
          "nl_meppel": "メッペル",
          "ch_zug": "ズーク",
          "es_madrid": "マドリード",
          "pt_lisbon": "リスボン",
          "md_chisinau": "キシナウ",
          "ie_dublin": "ダブリン",
          "ro_bucharest": "ブカレスト",
          "hu_budapest": "ブダペスト",
          "cz_veseli": "ヴェセリ",
          "lv_riga": "リガ",
          "ua_kyiv": "キエフ",
          "il_tel_aviv": "テルアビブ",
          "tr_izmir": "イズミール",
          "pl_warsaw": "ワルシャワ",
          "bg_sofia": "ソフィア",
          "kz_almaty": "アルマトイ",
          "rs_belgrade": "ベオグラード",
          "sk_bratislava": "ブラチスラバ",
          "it_rome": "ローマ",
          "gr_thessaloniki": "セラノキ",
          "us_secaucus_free": "セコーカス",
          "ca_beauharnois": "ボーハルノワ",
          "us_ashburn": "アシュバーン",
      }
  }
}