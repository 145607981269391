export default {
  "languages": {
    "en": "Englisch",
    "ru": "Russisch",
    "de": "Deutsch",
    "es": "Spanisch",
    "fr": "Französisch",
    "ja": "Japanisch"
  },
  "hello": {
    "title": "Schützen Sie Ihre Privatsphäre mit einem Klick",
    "login": "Anmeldung",
    "register": "Registrieren"
  },
  "servers": {
    "search": "Suche"
  },
  "drawer": {
    "vpn": "VPN",
    "settings": "Einstellungen",
    "support": "Hilfe"
  },
  "theme": {
    "current": "Aktuelles Thema",
    "system": "System",
    "dark": "Dunkel",
    "light": "Hell"
  },
  "paywall": {
    "title": "EchoAI Premium",
    "upgradeButton": "AUF PREMIUM UPGRADEN",
    "titleModal": "Jetzt Premium abonnieren",
    "subtitleModal": "Zugriff auf alle kostenpflichtigen Funktionen",
    "subtitles": [
      "Absolut KEINE WERBUNG",
      "Blitzschnelle Verbindung",
      "Verschlüsselung nach Militärstandard",
      "Zugriff auf alle Server",
      "Technischer Support",
      "Alle kommenden Funktionen"
    ],
    "awaiterText": "Wartet auf die Antwort des Kaufservers",
    "subscribeButton": "KOSTENLOS TESTEN",
    "subscribeButtonNoTrial": "ABONNIERE JETZT",
    "trial": "Dann 3 Tage kostenlose Testversion",
    "noTrial": "Automatisch erneuerbares Abonnement",
    "terms": "Nutzungsbedingungen",
    "privacy": "Datenschutz-Bestimmungen",
    "restore": "Kauf wiederherstellen"
  },
  "dialogs": {
    "wireguardInstall": {
      "title": "Installieren Sie WireGuard",
      "text": "EchoAI verwendet offizielle WireGuard®-Client-Tunnel, um Ihre Verbindung zu sichern. Drücken Sie die Schaltfläche unten und erlauben Sie, dass die Installation fortgesetzt wird.",
      "button": "Installieren"
    },
    "serviceInstall": {
      "title": "Richten Sie den EchoAI-Dienst ein",
      "text": "EchoAI verwendet einen Hintergrunddienst, um Ihren Verbindungsstatus zu kontrollieren. Drücken Sie die Schaltfläche unten und erlauben Sie, dass die Installation fortgesetzt wird.",
      "button": "Einrichtungsservice"
    },
    "serviceRun": {
      "title": "Führen Sie den EchoAI-Dienst aus",
      "text": "EchoAI-Hintergrunddienst läuft nicht. Drücken Sie die Schaltfläche unten, um fortzufahren.",
      "button": "Dienst starten"
    },
    "serviceRemove": {
      "title": "Entfernen Sie den EchoAI-Dienst",
      "text": "Der EchoAI-Hintergrunddienst muss installiert sein und ausgeführt werden, um Ihren Verbindungsstatus zu kontrollieren. Drücken Sie die Schaltfläche unten, um es trotzdem zu entfernen.",
      "button": "Dienst entfernen"
    },
    "tunnelRemove": {
      "title": "Entfernen Sie den EchoAI Wireguard-Tunnel",
      "text": "EchoAI Wireguard Tunnel kontrolliert Ihre Verbindung. Drücken Sie die Taste unten, um zu stoppen und es trotzdem zu entfernen.",
      "button": "Tunnel entfernen"
    },
    "accountExitDialog": {
      "title": "Konto verlassen",
      "text": "Sind Sie sicher, dass Sie Ihr Echo-Konto verlassen möchten?",
      "mainButton": "Ausfahrt",
      "cancelButton": "Stornieren"
    },
    "updateDialog": {
      "title": "Update verfügbar",
      "text": "Das EchoAI-Update ist zur Installation bereit. Bitte starten Sie die App neu, um fortzufahren.",
      "mainButton": "Neustart",
      "cancelButton": "Später"
    }
  },
  "vpn": {
    "title": "Bevorzugter Ort",
    "buttonConnect": "VERBINDEN",
    "buttonDisconnect": "TRENNEN",
    "freeBadge": "FREE",
    "premiumBannerLine1": "3 Tage kostenlos EchoAI Premium",
    "premiumBannerLine2": "Keine Werbung & schnellste private Server",
    "securedConnection": "Schnelle sichere Verbindung",
    "insecureConnection": "Unsichere Verbindung",
    "VPNStates": [
      "Getrennt",
      "Anschließen",
      "Verbunden",
      "Trennen",
      "Error"
    ]
  },
  "support": {
    "title": "Steuerung & Support",
    "wgReinstall": "Installieren Sie WireGuard®",
    "wgReinstallDesc": "Öffnen Sie den WireGuard-Setup-Dialog",
    "wgReinstallButton": "Offen",
    "wgReinstallButtonInstalled": "Eingerichtet",
    "wgReinstallButtonNotInstalled": "Nicht installiert",
    "svcReinstall": "Installieren Sie den EchoAI-Dienst",
    "svcReinstallDesc": "Öffnen Sie den Einrichtungsdialog des EchoAI-Dienstes",
    "svcReinstallButton": "Offen",
    "svcReinstallButtonInstalled": "Eingerichtet",
    "svcReinstallButtonNotInstalled": "Nicht installiert",
    "svcRun": "Führen Sie den EchoAI-Dienst aus",
    "svcRunDesc": "Öffnen Sie das Dialogfeld EchoAI-Dienst neu starten",
    "svcRunButton": "Offen",
    "svcRunButtonRunning": "Läuft",
    "svcRunButtonStopped": "Gestoppt",
    "svcRemove": "Entfernen Sie den EchoAI-Dienst",
    "svcRemoveDesc": "Öffnen Sie den Dialog zum Entfernen des EchoAI-Dienstes",
    "svcRemoveButton": "Entfernen",
    "wgTunnelRemove": "Stoppen Sie den WireGuard-Tunnel",
    "wgTunnelRemoveDesc": "Tun Sie dies bei Verbindungsproblemen",
    "wgTunnelRemoveButton": "Entfernen",
    "wgTunnelRemoveButtonRunning": "Läuft",
    "wgTunnelRemoveButtonStopped": "Gestoppt"
  },
  "settings": {
    "title": "Einstellungen",
    "language": "Sprache",
    "theme": "Thema",
    "themeDesc": "Ändere das Erscheinungsbild der App",
    "account": "Echo-Konto",
    "accountControl": "Kontosteuerung",
    "accountControlDesc": "Kontosteuerungsseite öffnen",
    "accountControlButton": "Offen",
    "accountLogin": "Einloggen",
    "accountLogout": "Ausloggen",
    "accountRemove": "Konto löschen",
    "subsControl": "Abonnementkontrolle",
    "startWithSystem": "Beginnen Sie mit System",
    "startWithSystemDesc": "Starten Sie EchoAI mit Systemstart",
    "startHidden": "Beginnen Sie versteckt",
    "startHiddenDesc": "Starten Sie EchoAI minimiert",
    "tracking": "Anonyme Nutzungsdaten senden",
    "trackingDesc": "Erlauben Sie uns, unsere App zu verbessern"
  },
  "account": {
    "title": "Echo-Konto",
    "errors": {
      "invalid_password": "Falsche Email oder Passwort. Versuchen Sie es nochmal",
      "invalid_code": "Falscher Code. Versuchen Sie es nochmal",
      "get_profile_error": "Falsche Email oder Passwort. Versuchen Sie es nochmal",
      "emptyEmail": "E-Mail darf nicht leer sein!",
      "incorrectEmail": "Falsche E-Mail Adresse!",
      "emptyPass": "Passwort darf nicht leer sein!",
      "emptyCode": "Code darf nicht leer sein!"
    },
    "login": {
      "title": "Einloggen",
      "createPre": "Neuer Benutzer?",
      "createLink": "Registrieren",
      "emailLabel": "E-Mail",
      "emailPlaceholder": "john.doe@gmail.com",
      "passLabel": "Passwort",
      "passPlaceholder": "**************",
      "mainBtn": "Fortsetzen",
      "resetLink": "Passwort vergessen?",
      "otcBtn": "Senden Sie mir einen Login-Code"
    },
    "register": {
      "title": "Registrieren",
      "loginPre": "Sie haben bereits ein Konto?",
      "loginLink": "Einloggen",
      "emailLabel": "E-Mail",
      "emailPlaceholder": "john.doe@gmail.com",
      "passLabel": "Passwort",
      "passPlaceholder": "**************",
      "mainBtn": "Fortsetzen"
    },
    "resetPass": {
      "title": "Passwort zurücksetzen",
      "createPre": "Neuer Benutzer?",
      "createLink": "Registrieren",
      "emailLabel": "E-Mail",
      "emailPlaceholder": "john.doe@gmail.com",
      "mainBtn": "Code senden",
      "resetLink": "Haben Sie bereits einen Reset-Code?",
      "otcBtn": "Senden Sie mir einen Login-Code"
    },
    "updatePass": {
      "title": "Passwort erneuern",
      "createPre": "Neuer Benutzer?",
      "createLink": "Registrieren",
      "codeLabel": "Code zurücksetzen",
      "codePlaceholder": "Code zurücksetzen",
      "passLabel": "Neues Kennwort",
      "passPlaceholder": "Neues Kennwort",
      "mainBtn": "Fortsetzen",
      "resetLink": "Erhalten Sie keinen Reset-Code?",
      "otcBtn": "Senden Sie mir einen Login-Code"
    },
    "loginSendCode": {
      "title": "Melden Sie sich mit Code an",
      "createPre": "Neuer Benutzer?",
      "createLink": "Registrieren",
      "emailLabel": "E-Mail",
      "emailPlaceholder": "john.doe@gmail.com",
      "mainBtn": "Fortsetzen",
      "otcLink": "Sie haben bereits einen Login-Code?",
      "loginBtn": "Mit Passwort anmelden"
    },
    "loginCheckCode": {
      "title": "Melden Sie sich mit Code an",
      "createPre": "Neuer Benutzer?",
      "createLink": "Registrieren",
      "emailLabel": "E-Mail",
      "emailPlaceholder": "john.doe@gmail.com",
      "codeLabel": "Login-Code",
      "codePlaceholder": "123456",
      "mainBtn": "Fortsetzen",
      "otcLink": "Anmeldecode nicht erhalten?",
      "loginBtn": "Mit Passwort anmelden"
    },
    "remove": {
      "title": "Konto entfernen",
      "message": "Sind Sie sicher, dass Sie Ihr Echo-Konto wirklich dauerhaft löschen möchten?\nDiese Aktion ist irreversibel!\nMöglicherweise müssen Sie Ihr Abonnement nach dem Löschen manuell kündigen.",
      "cancelBtn": "Absagen",
      "removeBtn": "Link zum Entfernen per E-Mail an mich senden"
    }
  },
  "location": {
    "region": {
      "Dev": "Dev",
      "Auto": "Auto",
      "Favorites": "Favoriten",
      "Latest": "Neueste",
      "Free": "Kostenlos",
      "World": "Welt",
      "Americas": "Amerika",
      "Europe": "Europa",
      "Asia": "Asien",
      "AsiaPacific": "Asien-Pazifik",
      "Australia": "Australien"
    },
    "country": {
      "CA": "Kanada",
      "MX": "Mexiko",
      "SE": "Schweden",
      "US": "Vereinigte Staaten",
      "NL": "Niederlande",
      "DE": "Deutschland",
      "GB": "Großbritannien",
      "FR": "Frankreich",
      "IN": "Indien",
      "KR": "Südkorea",
      "SG": "Republik Singapur",
      "JP": "Japan",
      "AU": "Australien",
      "RU": "Russland",
      "FI": "Finnland",
      "HK": "Hongkong SAR",
      "CH": "Schweiz",
      "ES": "Spanien",
      "PT": "Portugal",
      "MD": "Moldawien",
      "IE": "Irland",
      "RO": "Rumänien",
      "HU": "Ungarn",
      "CZ": "Tschechien",
      "LV": "Lettland",
      "UA": "Ukraine",
      "IL": "Israel",
      "TR": "Türkei",
      "PL": "Polen",
      "BG": "Bulgarien",
      "KZ": "Kasachstan",
      "RS": "Serbien",
      "SK": "Slowakei",
      "IT": "Italien",
      "GR": "Griechenland"
    },
    "city": {
      "dev": "Dev MSK",
      "auto_fastest": "Schnellster Standort",
      "auto_nearest": "Nächstgelegenes Land",
      "fr_paris_free": "Paris",
      "us_dallas_free": "Dallas",
      "de_frankfurt_free": "Frankfurt",
      "us_ny_free": "New York",
      "ru_moscow_free": "Moskau",
      "auto": "Auto",
      "ca_toronto": "Toronto",
      "se_stockholm": "Stockholm",
      "mx_mexico": "Mexiko-Stadt",
      "us_atlanta": "Atlanta",
      "us_chicago": "Chicago",
      "us_dallas": "Dallas",
      "us_la": "Los Angeles",
      "us_miami": "Miami",
      "us_ny": "New York",
      "us_sf": "San Francisco",
      "us_seattle": "Seattle",
      "us_sv": "Silicon Valley",
      "nl_amsterdam": "Amsterdam",
      "de_frankfurt": "Frankfurt",
      "gb_london": "London",
      "fr_paris": "Paris",
      "in_bengaluru": "Bengaluru",
      "kr_seoul": "Seoul",
      "sg_singapore": "Singapur",
      "jp_tokio": "Tokio",
      "au_sydney": "Sydney",
      "ru_moscow": "Moskau",
      "gb_coventry": "Coventry",
      "fi_helsinki": "Helsinki",
      "us_secaucus": "Secaukus",
      "hk_hongkong": "Hongkong",
      "nl_meppel": "Meppel",
      "ch_zug": "Zug",
      "es_madrid": "Madrid",
      "pt_lisbon": "Lissabon",
      "md_chisinau": "Chisinau",
      "ie_dublin": "Dublin",
      "ro_bucharest": "Bukarest",
      "hu_budapest": "Budapest",
      "cz_veseli": "Veseli nad Luznici",
      "lv_riga": "Riga",
      "ua_kyiv": "Kiew",
      "il_tel_aviv": "Tel Aviv",
      "tr_izmir": "Izmir",
      "pl_warsaw": "Warschau",
      "bg_sofia": "Sofia",
      "kz_almaty": "Almaty",
      "rs_belgrade": "Belgrad",
      "sk_bratislava": "Bratislava",
      "it_rome": "Rom",
      "gr_thessaloniki": "Thessaloniki",
      "us_secaucus_free": "Secaucus",
      "ca_beauharnois": "Beauharnois",
      "us_ashburn": "Ashburn",
    }
  }
}
