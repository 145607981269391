import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { useRecoilValue } from 'recoil';

import { bgState } from '../../state';
import { Input } from "./Input";

const isDev = false //process.env.NODE_ENV === 'development';

const variants = {
  default: {
    linear: { "x": 20, "y": 100, "r": 135, "color1": "#6e2424", "color2": "#0068be" },
    radial: { "w": 150, "h": 360, "x": 0, "y": 0, "r": 29, "color": "#000000" },
  },
}

export function Background() {
  return (
    <>
      <Linear />
      <Radial />
    </>
  )
}

function Linear() {
  const bg = useRecoilValue(bgState);

  const [x, setX] = useState(20);
  const [y, setY] = useState(100);
  const [rotate, setRotate] = useState(135);

  const transition = isDev ? { delay: 0 } : { type: "spring" }
  const variant = variants[bg] || variants.default;
  const color1 = variant.linear.color1
  const color2 = variant.linear.color2

  useEffect(() => {
    setX(variant.linear.x);
    setY(variant.linear.y);
    setRotate(variant.linear.r);
  }, [bg])

  return (
    <>
      <motion.div
        layout
        style={{
          position: 'fixed',
          width: '100%',
          height: '120%',
          top: 0,
          left: 0,
          zIndex: -200,
        }}
        initial={{
          backgroundImage: `linear-gradient(${rotate}deg, ${color1} ${x}%, ${color2} ${y}%)`,
        }}
        animate={{
          backgroundImage: `linear-gradient(${rotate}deg, ${color1} ${x}%, ${color2} ${y}%)`,
        }}
        transition={transition}
      />

      <LinearControls
        x={x}
        setX={setX}
        y={y}
        setY={setY}
        r={rotate}
        setRotate={setRotate}
      />
    </>
  );
}

function LinearControls(props) {
  const { x, setX, y, setY, r, setRotate } = props;

  if (!isDev) return null

  return (
    <Box sx={{
      position: 'fixed',
      width: 'auto',
      height: 'auto',
      bottom: '20vh',
      right: '1vw',
      zIndex: 999,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    }}>
      LINEAR
      <Input value={x} set={setX} min={0} max={200}>
        x
      </Input>
      <Input value={y} set={setY} min={0} max={200}>
        y
      </Input>
      <Input value={r} set={setRotate} min={-180} max={180}>
        r
      </Input>
      <Button onClick={() => {
        navigator.clipboard.writeText(JSON.stringify({
          x,
          y,
          r,
          color1: '#116A55',
          color2: '#BE8900',
        }))
      }}>
        Copy
      </Button>
    </Box>
  )
}

function Radial() {
  const bg = useRecoilValue(bgState);

  const [w, setW] = useState(200);
  const [h, setH] = useState(400);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [rotate, setRotate] = useState(0);

  const variant = variants[bg] || variants.default;
  const color = variant.radial.color
  const transition = isDev ? { delay: 0 } : { type: "spring" }

  useEffect(() => {
    setW(variant.radial.w);
    setH(variant.radial.h);
    setX(variant.radial.x);
    setY(variant.radial.y);
    setRotate(variant.radial.r);
  }, [bg])

  return (
    <>
      <motion.div
        layout
        style={{
          position: 'fixed',
          background: `radial-gradient(#00000000 0%, ${color}ee 66%, ${color}ff 99%)`,
          boxShadow: `0 0 0 200vmax ${color}ff, inset 0 0 3px 3px ${color}ff`,
          zIndex: -100,
        }}
        initial={{
          width: `${w * 1.515}vmin`,
          height: `${h * 1.515}vmin`,
          top: `${50 + y}vh`,
          left: `${50 + x}vw`,
          transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
        }}
        animate={{
          width: `${w * 1.515}vmin`,
          height: `${h * 1.515}vmin`,
          top: `${50 + y}vh`,
          left: `${50 + x}vw`,
          transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
        }}
        transition={transition}
      />

      <RadialControls
        w={w}
        setW={setW}
        h={h}
        setH={setH}
        x={x}
        setX={setX}
        y={y}
        setY={setY}
        rotate={rotate}
        setRotate={setRotate}
      />
    </>
  );
}

function RadialControls(props) {
  const { w, setW, h, setH, x, setX, y, setY, rotate, setRotate } = props;

  if (!isDev) return null

  return (
    <Box sx={{
      position: 'fixed',
      width: 'auto',
      height: 'auto',
      bottom: '1vh',
      right: '1vw',
      zIndex: 999,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    }}>
      RADIAL
      <Input value={w} set={setW} min={0} max={500}>w</Input>
      <Input value={h} set={setH} min={0} max={500}>h</Input>
      <Input value={x} set={setX} min={-100} max={100}>x</Input>
      <Input value={y} set={setY} min={-100} max={100}>y</Input>
      <Input value={rotate} set={setRotate} min={-180} max={180}>r</Input>

      <Button onClick={() => {
        navigator.clipboard.writeText(JSON.stringify({ w, h, x, y, r: rotate, color: '#000000', }))
      }}>
        Copy
      </Button>
    </Box>
  )
}


export default Background;