import React, { useState, useEffect, useMemo, useRef } from 'react';
import localforage from 'localforage'
import { nanoid } from 'nanoid';
import _ from 'lodash'
import { addMessage, getLastMessageId, getFirstMessageId } from './state/db'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  setSendMessage,
  getChunks,
  setChunks,
  getOnNewMessageHandler,
  getOnChatChunkHandler,
  getOnUpdateMessageHandler,
  // recoil
  accountState,
  tokenState,
  isSubscribedSelector,
  isWSAuthorizedState,
  subscribedState,
  balanceState,
  accountBalanceState,
  typingState,
  chunksState,
  lastMessageIdState,
  isWsConnectedState,
  serverSettingsState,
} from '#state'

import * as requests from '#src/requests'
import useWebSocket, { ReadyState } from 'react-use-websocket';

// const wsBase = 'ws://localhost:6174'
const wsBase = 'wss://ws.echoai.in'
const debug = true

export const useWSAPI = (url) => {
  const token = useRecoilValue(tokenState);
  const [account, setAccount] = useRecoilState(accountState)
  const [isWSAuthorized, setIsWSAuthorized] = useRecoilState(isWSAuthorizedState)
  const setTyping = useSetRecoilState(typingState)
  const setChunks = useSetRecoilState(chunksState)
  const setSubscribed = useSetRecoilState(subscribedState)
  const setBalance = useSetRecoilState(balanceState)
  const setIsWsConnected = useSetRecoilState(isWsConnectedState)
  const setLastMessageId = useSetRecoilState(lastMessageIdState)
  const setServerSettings = useSetRecoilState(serverSettingsState)
  const setAccountBalance = useSetRecoilState(accountBalanceState)

  const didUnmount = useRef(false);

  // const onWSMessage = getOnWSMessage(token)
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(wsBase, {
    shouldReconnect: (closeEvent) => {
      return didUnmount.current === false;
    },
    reconnectAttempts: 10,
    reconnectInterval: 5000,
    // onMessage: onWSMessage,
    onOpen: (ev) => {
      if (debug) console.log('useWebSocket connected', ev)
      // wss.send(encode(JSON.stringify({ reqId: 'r2', action: 'test', foo: 'bar' })))
      // wss.send(encode(JSON.stringify({ reqId: 'r3', action: 'asd', bar: 'baz' })))
    },
    onClose: (ev) => {
      if (debug) console.log('useWebSocket disconnected', ev)
      setIsWSAuthorized(false)
    },
    onError: (ev) => {
      if (debug) console.log('useWebSocket error', ev)
      setIsWSAuthorized(false)
    },
  });

  const sendEncoded = useMemo(() => {
    const f = (a) => {
      if (debug) console.log('sendEncoded', a)
      sendMessage(encode(JSON.stringify(a)))
    }
    setSendMessage(f)
    return f
  }, [sendMessage])



  useEffect(() => {
    const ws = getWebSocket()
    if (ws && ws.binaryType !== 'arraybuffer') {
      if (debug) console.log('ws.binaryType set to arraybuffer')
      ws.binaryType = 'arraybuffer'
    }
  }, [readyState, getWebSocket]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && token && account) {
      sendEncoded({ reqId: nanoid(), action: 'auth', token })
      // sendMessage(encode(JSON.stringify({ reqId: 'r1', action: 'auth', token })))
    }
  }, [readyState, sendEncoded, token, account]);

  useEffect(() => {
    if (lastMessage) {
      let msg
      // console.log('onmessage event', ev)
      if (lastMessage.data instanceof ArrayBuffer) {
        msg = decode(lastMessage.data)
        // if (debug) console.log('lastMessage', msg)
      } else {
        msg = lastMessage.data
        // if (debug) console.log('lastMessage', msg)
      }
      messageRouter({
        message: msg,
        sendEncoded,
        isWSAuthorized,
        setIsWSAuthorized,
        setTyping,
        setChunks,
        setSubscribed,
        setBalance,
        setIsWsConnected,
        setLastMessageId,
        setServerSettings,
      })
    }
  }, [lastMessage, sendEncoded, isWSAuthorized, setIsWSAuthorized]);

  function messageRouter(props) {
    try {
      const { message, sendEncoded, isWSAuthorized, setIsWSAuthorized } = props
      const payload = JSON.parse(message)
      console.log('messageRouter', payload)
      
      switch (payload.action) {
        case 'auth':
          onAuthMessage(payload)
          break
        case 'account':
          onAccountMessage(payload)
          break
        case 'updateBalance':
          onUpdateBalanceMessage(payload)
          break
        case 'typing':
          onTypingMessage(payload)
          break
        case 'chatmsg':
          onChatMSG(payload)
          break
        case 'chunk':
          onChunkMessage(payload)
          break
        // case 'delivery':
          // onDeliveryMessage(payload)
          // break
        // case 'lastMsgId':
          // onLastMessageIdResult(payload)
          // break
        // case 'firstMsgId':
          // onFirstMessageIdResult(payload)
          // break
        // case 'syncSettings':
          // onSyncSettings(payload)
          // break
        // case 'tts':
          // onTTS(payload)
          // break
        default:
          console.log('wss.onmessage unknown action', payload)
      }
    } catch (error) {
      console.log('wss.onmessage error', props, error)
    }
  }

  async function sendLastMessageId() {
    try {
      let lastMsgId = await getLastMessageId()

      const msg = JSON.stringify({
        reqId: nanoid(),
        action: 'lastMsgId',
        lastMsgId,
      })
      sendEncoded(msg)
    } catch (error) {
      console.log('sendLastMessageId failed', error)
    }
  }

  // async function onLastMessageIdResult(payload) {
  //   console.log('onLastMessageId missing msgs', payload.msgs.length)

  //   let index = 0
  //   const chunks = _.chunk(payload.msgs, 5)

  //   function heavyProcessing(partOfArray) {
  //     for (const msg of partOfArray) {
  //       const { _id, mid, created, text, image, video, audio, tts, senderId, chatId } = msg
  //       try {
  //         let result = sqlite.db.execute(`
  //         INSERT INTO messages2 (_id, mid, createdAt, text, image, video, audio, tts, senderId, chatId)
  //         VALUES (?,?,?,?,?,?,?,?,?,?)
  //         ON CONFLICT(_id) DO UPDATE
  //         SET _id = excluded._id`,
  //           [_id, mid, created, text, image, video, audio, tts, senderId, chatId],
  //         )
  //         // console.log('onLastMessageIdResult insert result', result)
  //       } catch (error) {
  //         console.log('onLastMessageIdResult heavyProcessing failed', partOfArray, error)
  //       }

  //     }
  //   }

  //   function processNextChunk() {
  //     if (index < chunks.length) {
  //       heavyProcessing(chunks[index])
  //       index++
  //       setTimeout(processNextChunk, 33)
  //     } else {
  //       console.log('onLastMessageId has processed all chunks')
  //       setTimeout(sendFirstMessageId, 2000)
  //     }
  //   }
  //   processNextChunk()


  //   if (payload.msgs.length) {
  //     setLastMessageId(payload.msgs[payload.msgs.length - 1].mid)
  //   }
  //   // {
  //   //   reqId,
  //   //   action: 'lastMsgId',
  //   //   msgs: newMessages
  //   // }
  //   // [{
  //   //   "_id": "656b52863af1b1339f0bb0dd",
  //   //   "botId": "LinguistLoreBot",
  //   //   "chatId": "LinguistLoreBot",
  //   //   "created": 1701532296404,
  //   //   "mid": "TI2OMHROO9yfFmBb",
  //   //   "pending": false,
  //   //   "received": true,
  //   //   "senderId": "HoMriFEoplfGzFCkv7Chj2RE4zr2",
  //   //   "sent": true,
  //   //   "system": false,
  //   //   "text": "Расскажи про эти особенности упрощающие процесс счета",
  //   //   "userId": "HoMriFEoplfGzFCkv7Chj2RE4zr2"
  //   // }]
  // }

  async function sendFirstMessageId() {
    try {
      let firstMsgId = await getFirstMessageId()
      const msg = JSON.stringify({
        reqId: nanoid(),
        action: 'firstMsgId',
        firstMsgId,
      })
      sendEncoded(msg)
    } catch (error) {
      console.log('sendFirstMessageId failed', error)
    }
  }

  // async function onFirstMessageIdResult(payload) {
  //   console.log('onFirstMessageIdResult missing msgs', payload.msgs.length, payload.hasMore)

  //   let index = 0
  //   const chunks = _.chunk(payload.msgs, 1)

  //   function heavyProcessing(partOfArray) {
  //     for (const msg of partOfArray) {
  //       try {
  //         const { _id, mid, created, text, image, video, audio, tts, senderId, chatId } = msg
  //         let result = sqlite.db.execute(`
  //           INSERT INTO messages2 (_id, mid, createdAt, text, image, video, audio, tts, senderId, chatId)
  //           VALUES (?,?,?,?,?,?,?,?,?,?)
  //           ON CONFLICT(_id) DO UPDATE
  //           SET _id = excluded._id`,
  //           [_id, mid, created, text, image, video, audio, tts, senderId, chatId],
  //         )
  //         // console.log('onFirstMessageIdResult insert result', result)
  //       } catch (error) {
  //         console.log('onFirstMessageIdResult heavyProcessing failed', partOfArray, error)
  //       }
  //     }
  //   }

  //   function processNextChunk() {
  //     if (index < chunks.length) {
  //       heavyProcessing(chunks[index])
  //       index++
  //       setTimeout(processNextChunk, 33)
  //     } else {
  //       console.log('onFirstMessageIdResult has processed all chunks, hasMore', payload.hasMore)
  //       if (payload.hasMore) {
  //         setTimeout(sendFirstMessageId, 2000)
  //       }
  //     }
  //   }
  //   processNextChunk()

  // }


  async function onAuthMessage(payload) {
    if (payload.success) {
      console.log('ws auth success', payload.id)
      setIsWsConnected(true)
    } else if (payload.error) {
      console.log('ws auth error', payload.error)
    } else {
      console.log('ws auth failed', payload.id)
    }
  }

  async function onAccountMessage(payload) {
    if (payload.success) {
      const { subscribed, balance, settings } = payload.data
      console.log('ws account success subscribed', subscribed, balance)
      setSubscribed(subscribed)
      setBalance(balance)
      if (settings) {
        console.log('setServerSettings', settings)
        setServerSettings(settings)
      } else {
        setServerSettings({})
      }

      sendLastMessageId()
    } else {
      console.log('ws account failed', payload)
    }
  }

  async function onSyncSettings(payload) {
    const { success, settings } = payload
    if (success) {
      if (settings) {
        console.log('setServerSettings', settings)
        setServerSettings(settings)
      } else {
        setServerSettings({})
      }
    } else {
      console.log('ws onSyncSettings failed', payload)
    }
  }

  // async function onTTS(payload) {
  //   const { success, data } = payload
  //   if (success) {
  //     const { _id, url, title, artist } = data
  //     try {
  //       // update sqlite
  //       let result = sqlite.db.execute(
  //         `UPDATE messages2 
  //         SET tts = ?
  //         WHERE _id = ?`,
  //         [url, _id],
  //       )
  //       console.log('tts update result', result)
  //     } catch (error) {
  //       console.log('tts update error', payload, error)
  //     }

  //     const handler = getOnUpdateMessageHandler()
  //     if (handler) {
  //       handler({
  //         _id,
  //         tts: url,
  //       })
  //     }

  //     const ttsid = `tts:${_id}`
  //     const audio = {
  //       id: ttsid,
  //       url,
  //       title: title || 'Text to Speech',
  //       artist: artist || 'Chat42',
  //     }
  //     // await TrackPlayer.setQueue([audio])
  //     // TrackPlayer.play()
  //   } else {
  //     console.log('ws onSyncSettings failed', payload)
  //   }
  // }

  async function onUpdateBalanceMessage(payload) {
    // const { balance } = payload
    try {
      console.log('ws updateBalance', payload.balance)
      setBalance(payload.balance)
      setAccountBalance(payload.balance)
    } catch (error) {
      console.log('ws updateBalance failed', payload)
    }
  }

  async function onTypingMessage(payload) {
    try {
      const { chatId } = payload
      const enabled = payload.isTyping || payload.isTyping === undefined
      setTyping(typing => {
        console.log('bot typing', chatId, payload)
        return { ...typing, [chatId]: enabled }
      })
    } catch (error) {
      console.log('bot typing failed', payload)
    }
  }

  async function onChatMSG(payload) {
    // handle new message
    // {
    //   action: 'chatmsg',
    //   _id: 'sdkfjfjg',
    //   msg: {
    //     id: replyId,
    //     createdAt: Date.now(),
    //     text: replyText,
    //     senderId: chatId,
    //     chatId,
    //   },
    // }
    try {
      // console.log('bot chatmsg', payload)
      const { id, createdAt, text, image, video, audio, tts, senderId, chatId } = payload.msg
      setTyping(typing => {
        return { ...typing, [chatId]: false }
      })

      if (payload._id) {
        // update sqlite
        try {
          const time = createdAt || Date.now()

          addMessage({
            _id: payload._id,
            mid: id,
            senderId,
            chatId,
            createdAt: time,
            text,
            tts,
            image,
            audio,
            video,
          })

          setLastMessageId(id)

          const handler = getOnUpdateMessageHandler()
          if (handler) {
            const opts = {
              _id: payload._id,
              id,
            }
            console.log('getOnUpdateMessageHandler', opts)
            handler(opts)
          }
          // haptic('effectHeavyClick')
        } catch (error) {
          console.log('new chatmsg INSERT error', payload, error)
        }
      }
      let chunks = getChunks()

      if (chunks[chatId]?.id === id) {
        console.log('bot chatmsg stream done', id)
        chunks[chatId] = null

        const handler = getOnChatChunkHandler()
        if (handler) {
          handler({ ...chunks })
        }
      } else {
        console.log('bot chatmsg done', id)
        const msg = {
          _id: id,
          createdAt: createdAt,
          text,
          image,
          video,
          audio,
          tts,
        }

        const handler = getOnNewMessageHandler()
        if (handler) {
          handler(chatId, [msg])
        }
      }
    } catch (error) {
      console.log('bot chatmsg failed', payload)
    }
  }

  async function onChunkMessage(payload) {
    // handle new chunk
    // console.log('bot chunk', payload)
    // {
    //   action: 'chunk',
    //   chunk: {
    //     id: replyId,
    //     chatId,
    //     senderId: chatId,
    //     createdAt: chunk.created,
    //     delta: chunk.choices[0].delta,
    //   },
    // }

    const handler = getOnChatChunkHandler()

    const { chatId, createdAt, delta } = payload.chunk

    if (delta.content) {
      let chunks = getChunks()
      let chatChunk = chunks[chatId]
        ? { ...chunks[chatId] }
        : { ...payload.chunk }

      chatChunk.text = chatChunk.text || ''
      chatChunk.createdAt = createdAt * 1000 + 999

      if (chatChunk.delta.content) {
        chatChunk.text += delta.content
      }
      chunks[chatId] = chatChunk
      if (handler) {
        handler(chunks)
      }
    }
  }

  // async function onDeliveryMessage(payload) {
  //   console.log('bot delivery', payload)
  //   // {
  //   //   "action": "delivery",
  //   //   "_id": "657512217a61cb0b70a31b7c"
  //   //   "chatId": "LinguistLoreBot",
  //   //   "messageId": "Cl0WgKmzUvfnYgFj",
  //   //   "reqId": "NV1d6GsurMFRDiHt",
  //   //   "success": true
  //   // }
  //   if (payload.success) {
  //     try {
  //       const { _id, messageId, audio, stt } = payload
  //       // update sqlite
  //       let result = sqlite.db.execute(
  //         `UPDATE messages2 
  //         SET _id = ?
  //         WHERE mid = ?`,
  //         [_id, messageId],
  //       )

  //       if (audio) {
  //         const pathResult = sqlite.db.execute(
  //           `SELECT audio 
  //           FROM messages2
  //           WHERE mid = ?
  //           LIMIT 1`,
  //           [messageId],
  //         )
  //         console.log('messages audio result', pathResult)

  //         let result2 = sqlite.db.execute(
  //           `UPDATE messages2 
  //           SET audio = ?
  //           WHERE mid = ?`,
  //           [audio, messageId],
  //         )
  //         console.log('delivery update audio result', result2, audio, stt)

  //         const handler = getOnUpdateMessageHandler()
  //         if (handler) {
  //           const opts = {
  //             _id: messageId,
  //             id: messageId,
  //             audio,
  //           }
  //           console.log('audio UpdateMessage', opts)
  //           handler(opts)
  //         }

  //         try {
  //           const { rows } = pathResult
  //           let msgs = rows._array
  //           if (msgs.length > 0) {
  //             const path = msgs[0].audio
  //             // const removed = await RNFS.unlink(path)
  //             // console.log('delivery remove audio', path, removed)
  //           }
  //         } catch (error) {
  //           console.log('delivery remove failed', error)
  //         }
  //       }

  //       console.log('delivery update result', result)
  //     } catch (error) {
  //       console.log('delivery update error', payload, error)
  //     }
  //   }
  // }

};

const Empty = new Uint8Array(0)
const TE = new TextEncoder()
const TD = new TextDecoder()

function concat(...bufs) {
  let max = 0
  for (let i = 0; i < bufs.length; i++) {
    max += bufs[i].length
  }
  const out = new Uint8Array(max)
  let index = 0
  for (let i = 0; i < bufs.length; i++) {
    out.set(bufs[i], index)
    index += bufs[i].length
  }
  return out
}
function encode(...a) {
  const bufs = []
  for (let i = 0; i < a.length; i++) {
    bufs.push(TE.encode(a[i]))
  }
  if (bufs.length === 0) {
    return Empty
  }
  if (bufs.length === 1) {
    return bufs[0]
  }
  return concat(...bufs)
}
function decode(a) {
  if (!a || a.length === 0) {
    return ''
  }
  return TD.decode(a)
}

export default useWSAPI;